import { useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import getDefaultOptions from '../utils/default-options'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'

function useArticlesPath() {
  const { sitePlugin } = useStaticQuery(graphql`
    query {
      sitePlugin(name: { eq: "@babelcoder/gatsby-theme-blog" }) {
        pluginOptions {
          basePath
          articlesPath
        }
      }
    }
  `)
  const { basePath, articlesPath } = getDefaultOptions(sitePlugin.pluginOptions)
  const path = toSingleSlash(`${basePath}/${articlesPath}`)
  const getArticlePath = useCallback(
    (slug) => toSingleSlash(`${path}/${slug}`),
    [path]
  )

  return {
    basePath,
    articlesPath,
    path,
    getArticlePath,
  }
}

export default useArticlesPath
