import React from 'react'
import styled, { css } from 'styled-components'

const CourseMetaWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: ${theme.fontSizes.small};
    background-color: ${theme.colors.neutral.gray200};
    padding: calc(${theme.spacers.xsmall} / 2);
    border-radius: ${theme.round.xlarge};
    line-height: 1.5;
  `}
`

const CourseMetaIcon = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.gray600};
`

const CourseMetaValue = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacers.xsmall};
    color: ${theme.colors.neutral.gray2000};
  `}
`

const CourseMetaName = styled.div`
  ${({ theme }) => css`
    margin-left: ${`calc(${theme.spacers.xsmall} / 2)`};
    color: ${theme.colors.neutral.gray900};
  `}
`

function CourseItemMeta({ name, value, Icon }) {
  return (
    <CourseMetaWrapper>
      <CourseMetaIcon>
        <Icon></Icon>
      </CourseMetaIcon>
      <CourseMetaValue>{value}</CourseMetaValue>
      <CourseMetaName>{name}</CourseMetaName>
    </CourseMetaWrapper>
  )
}

export default CourseItemMeta
