import { useCallback } from 'react'

import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta'
import useArticlesPath from './useArticlesPath'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'

function useArticleSchema() {
  const { siteUrl } = useSiteMeta()
  const { getArticlePath } = useArticlesPath()

  const getSchema = useCallback(
    ({ slug, title, excerpt, coverImage, createdAtDate, updatedAtDate }) => {
      const path = getArticlePath(slug)

      return {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': toSingleSlash(`${siteUrl}/${path}`),
        },
        inLanguage: 'th-TH',
        name: title,
        image: toSingleSlash(
          `${siteUrl}${coverImage.childImageSharp.fixed.src}`
        ),
        headline: excerpt,
        url: toSingleSlash(`${siteUrl}${path}`),
        datePublished: createdAtDate,
        dateModified: updatedAtDate,
      }
    },
    [siteUrl, getArticlePath]
  )

  return getSchema
}

export default useArticleSchema
