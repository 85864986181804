import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { down } from 'styled-breakpoints'

const SectionLink = styled(Link)`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.white};
    border-radius: ${theme.round.large};
    box-shadow: 0 0 2px 2px ${theme.colors.neutral.gray300};
    overflow: hidden;
    transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out;

    &:hover {
      transform: perspective(500px) translateZ(1rem);
      transform-style: preserve-3d;
      box-shadow: 0 0 5px 5px ${theme.colors.accent.primaryLighten30};
    }
  `};
`

const SectionWrapper = styled.article`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const SectionTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1200};
    border-top: 1px solid ${theme.colors.neutral.gray200};
    padding: ${theme.spacers.normal};

    ${down('small')} {
      font-size: ${theme.fontSizes.medium};
    }
  `}
`

const SectionExcerpt = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray900};
    padding: 0 ${theme.spacers.normal};
    font-size: ${theme.fontSizes.small};
    margin-top: auto;

    ${down('small')} {
      font-size: ${theme.fontSizes.normal};
    }
  `}
`

function SectionImage({ image, type, alt }) {
  switch (type) {
    case 'url':
      return <img src={image} alt={alt} />
    case 'fluid':
    default:
      return <Img fluid={image}></Img>
  }
}

function SectionItem({
  to,
  image,
  title,
  excerpt,
  children,
  imageType = 'fluid',
  imageAlt,
}) {
  return (
    <SectionLink to={to}>
      <SectionWrapper>
        <SectionImage
          image={image}
          type={imageType}
          alt={imageAlt}
        ></SectionImage>
        <SectionTitle>{title}</SectionTitle>
        <SectionExcerpt>{excerpt}</SectionExcerpt>
        {children}
      </SectionWrapper>
    </SectionLink>
  )
}

export default SectionItem
