import React from 'react'
import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'

import Article from '@babelcoder/gatsby-theme-blog/src/components/articles/Article'
import Container from '@babelcoder/site/src/components/home/Container'
import { CourseItems } from '@babelcoder/site/src/components/home/OnlineCourses'

const PromotedCoursesWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.neutral.gray200};
`

const Title = styled.h3`
  ${({ theme }) => css`
    position: relative;
    text-align: center;
    font-size: ${theme.fontSizes.xlarge};
    margin-bottom: ${theme.spacers.normal};

    ${down('xsmall')} {
      font-size: ${theme.fontSizes.large};
    }

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: ${theme.fontSizes.large};
      height: 5px;
      border-radius: ${theme.round.large};
      background-color: ${theme.colors.main.primary};

      ${down('medium')} {
        left: 50%;
        transform: translate(-50%);
      }
    }
  `}
`

export default function (props) {
  return (
    <>
      <Article {...props}></Article>
      <PromotedCoursesWrapper>
        <Container>
          <Title>คอร์สออนไลน์ของเรา</Title>
          <CourseItems></CourseItems>
        </Container>
      </PromotedCoursesWrapper>
    </>
  )
}
