import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import { FaPlay, FaClock, FaVideo } from 'react-icons/fa';

import Container from './Container';
import CourseItemMeta from '@babelcoder/gatsby-theme-courses/src/components/courses/online/CourseItemMeta';
import SectionHeader from './SectionHeader';
import SectionList from './SectionList';
import SectionItem from './SectionItem';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';

const MetaList = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacers.normal};
    padding-top: 0;

    & > * + * {
      margin-left: ${theme.spacers.normal};
    }
  `}
`;

export const CourseItems = () => {
  const { getCoursePath } = useCoursesPath();
  const {
    onlineCourses: { edges: onlineCourses },
  } = useStaticQuery(graphql`
    query {
      onlineCourses: allCoursesYaml(
        limit: 3
        sort: { fields: createdAt, order: DESC }
        filter: { status: { eq: "public" }, type: { eq: "online" } }
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            price
            title
            meta {
              hours
              videos
            }
            image {
              childImageSharp {
                fluid(maxWidth: 350, quality: 50, webpQuality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <SectionList>
      {onlineCourses.map(({ node: course }) => (
        <SectionItem
          key={course.fields.slug}
          to={getCoursePath(course.fields.slug)}
          image={course.image.childImageSharp.fluid}
          title={course.title}
          excerpt={course.excerpt}
        >
          <MetaList>
            <CourseItemMeta
              name="ชม."
              value={course.meta.hours}
              Icon={FaClock}
            ></CourseItemMeta>
            <CourseItemMeta
              name="วิดีโอ"
              value={course.meta.videos}
              Icon={FaPlay}
            ></CourseItemMeta>
          </MetaList>
        </SectionItem>
      ))}
    </SectionList>
  );
};

function OnlineCourses() {
  const { basePath } = useCoursesPath();

  return (
    <Container>
      <section>
        <SectionHeader
          title="คอร์สวิดีโอล่าสุด"
          buttonText="ดูทั้งหมด"
          to={basePath}
          Icon={FaVideo}
        ></SectionHeader>
        <CourseItems></CourseItems>
      </section>
    </Container>
  );
}

export default OnlineCourses;
