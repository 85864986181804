function getDefaultOptions(options = {}) {
  return {
    basePath: '/',
    contentPath: 'data',
    articlesPath: '',
    ...options,
  }
}

module.exports = getDefaultOptions
