import React, { useState, useContext, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Context } from './mdx/AnchorHeadingProvider';

const Wrapper = styled.div`
  ${({ theme, visible }) => css`
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    min-height: 100%;
    width: ${visible ? '300px' : 0};
    background-color: ${theme.colors.neutral.white};
    box-shadow: -2px 0px 11px -2px ${theme.colors.neutral.gray700};
    transition: width 0.25s;
    z-index: ${theme.zIndex.popup};
  `}
`;

const Button = styled.div`
  position: absolute;
  top: 50%;
  right: 100%;
  transform: rotate(-90deg) translateY(35%);
  cursor: pointer;
  ${({ theme }) => css`
    border-top-left-radius: ${theme.round.large};
    border-top-right-radius: ${theme.round.large};
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
    padding: ${theme.spacers.xxsmall} ${theme.spacers.small};
    font-size: ${theme.fontSizes.small};
  `}
`;

const Header = styled.h2`
  margin: 0;
  width: 100%;
  text-align: center;

  ${({ theme }) => css`
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
  `}
`;
const ListContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray100};
    padding: ${theme.spacers.normal} 0;
  `}
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: list-item;
`;

const Item = styled.li`
  counter-increment: list-item;
  transition: background-color 0.25s;

  ${({ theme, isCurrent }) => css`
    ${isCurrent &&
    css`
      background-color: ${theme.colors.neutral.gray900};
    `};

    &:hover {
      background-color: ${theme.colors.neutral.gray800};
    }
  `}
`;

const TocItem = styled.div`
  ${({ theme, isCurrent }) => css`
    display: grid;
    grid-template-columns: ${theme.spacers.normal} 1fr;
    gap: ${theme.spacers.normal};
    cursor: pointer;
    padding: 0 ${theme.spacers.normal};
    grid-column: 2 / 3;
    color: ${theme.colors.neutral.gray1100};
    font-size: ${theme.fontSizes.small};
    transition: color 0.25s;

    ${isCurrent &&
    css`
      color: ${theme.colors.neutral.white};
    `};

    &:hover {
      color: ${theme.colors.neutral.white};
    }

    &::before {
      grid-column: 1 / 2;
      content: counter(list-item) '. ';
      text-align: right;
    }
  `}
`;

function TableOfContents({ toc: { items } }) {
  const { heading, refs } = useContext(Context);
  const [isTocShown, setIsTocShown] = useState(false);

  const toggleIsTocShown = useCallback(() => setIsTocShown(!isTocShown), [
    setIsTocShown,
    isTocShown,
  ]);

  if (typeof items === 'undefined') return null;

  const scrollToContent = (index) => () => {
    refs[index].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  return (
    <Wrapper visible={isTocShown}>
      <Button onClick={toggleIsTocShown}>สารบัญ</Button>
      <Header>สารบัญ</Header>
      <ListContainer>
        <List>
          {items.map((item, index) => {
            const { url, title } = item;
            const isCurrent = title === heading?.current?.textContent;

            return (
              <Item
                key={url}
                isCurrent={isCurrent}
                onClick={scrollToContent(index)}
              >
                <TocItem key={url} isCurrent={isCurrent}>
                  {title}
                </TocItem>
              </Item>
            );
          })}
        </List>
      </ListContainer>
    </Wrapper>
  );
}

export default TableOfContents;
