import React, { useEffect } from 'react'
import styled from 'styled-components'

import MDXRenderer from '@babelcoder/gatsby-theme-base/src/components/mdx/Renderer'
import AnchorHeadingProvider from '@babelcoder/gatsby-theme-base/src/components/mdx/AnchorHeadingProvider'
import TableOfContents from '@babelcoder/gatsby-theme-base/src/components/TableOfContents'
import ArticleHeader from './ArticleHeader'
import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO'
import useArticleSchema from '../../hooks/useArticleSchema'
import useArticlesPath from '../../hooks/useArticlesPath'

const Container = styled.article`
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacers.large};
`

function Article(article) {
  const {
    slug,
    body,
    title,
    excerpt,
    coverImage,
    includesMath,
    createdAt,
    createdAtDate,
    toc,
    authorImage,
  } = article
  const getSchema = useArticleSchema()
  const { getArticlePath } = useArticlesPath()
  const {
    src: coverImageURL,
    width: coverImageWidth,
    height: coverImageHeight,
  } = coverImage.childImageSharp.fixed

  useEffect(() => {
    if(typeof fbq === 'function') {
      // eslint-disable-next-line no-undef
      fbq('track', 'ViewContent', {
        content_type: 'article',
        content_ids: [slug],
        content_name: title,
      });
    }
  }, [slug, title])

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={getArticlePath(slug)}
        coverImageWidth={coverImageWidth}
        coverImageHeight={coverImageHeight}
        coverImageURL={coverImageURL}
        desc={excerpt}
        schema={getSchema(article)}
      ></SEO>
      <Container>
        <ArticleHeader
          title={title}
          createdAt={createdAt}
          createdAtDate={createdAtDate}
          authorImage={authorImage}
        ></ArticleHeader>
        <AnchorHeadingProvider>
          <MDXRenderer includesMath={includesMath}>{body}</MDXRenderer>
          <TableOfContents toc={toc}></TableOfContents>
        </AnchorHeadingProvider>
      </Container>
    </>
  )
}

export default Article
