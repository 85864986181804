import React from 'react'
import { graphql } from 'gatsby'

import Article from '../components/articles/Article'

export default function PageTemplate({ data: { mdx, authorImage } }) {
  const {
    body,
    tableOfContents: toc,
    frontmatter: {
      title,
      excerpt,
      createdAt,
      createdAtDate,
      updatedAtDate,
      includesMath,
      coverImage,
    },
    fields: { slug },
  } = mdx

  return (
    <Article
      body={body}
      title={title}
      excerpt={excerpt}
      createdAt={createdAt}
      createdAtDate={createdAtDate}
      updatedAtDate={updatedAtDate}
      coverImage={coverImage}
      includesMath={includesMath}
      toc={toc}
      slug={slug}
      authorImage={authorImage}
    ></Article>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      tableOfContents
      frontmatter {
        title
        excerpt
        createdAt(formatString: "DD MMM YYYY", locale: "th-TH")
        createdAtDate: createdAt
        updatedAtDate: updatedAt
        includesMath
        coverImage: image {
          childImageSharp {
            fixed(width: 730, height: 415, quality: 80, webpQuality: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
    authorImage: imageSharp(original: { src: { regex: "/author/" } }) {
      fluid(maxWidth: 450, quality: 50, webpQuality: 50) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
