import React from 'react'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'author-image'
    'title'
    'author-name'
    'meta';
  grid-template-rows: max-content;
  grid-template-columns: 1fr;
  justify-items: center;
  column-gap: 1rem;

  ${up('medium')} {
    grid-template-areas:
      'title title'
      'author-image author-name'
      'author-image meta';
    grid-template-rows: max-content;
    grid-template-columns: 50px 1fr;
    align-items: center;
    justify-items: start;
  }
`

const Title = styled.h1`
  grid-area: title;
  margin: 0;
  text-align: center;
  width: 100%;

  ${up('medium')} {
    text-align: left;
  }
`

const Image = styled(Img)`
  grid-area: author-image;
  border-radius: 50%;
  width: 75px;

  ${up('medium')} {
    width: 100%;
  }
`

const AuthorName = styled.div`
  grid-area: author-name;
  color: ${({ theme }) => theme.colors.neutral.gray1200};
`

const PublishedDate = styled.time`
  grid-area: meta;

  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray900};

    ${up('medium')} {
      font-size: ${theme.fontSizes.small};
    }
  `};
`

const Break = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.neutral.gray400};
    margin: ${theme.spacers.normal} 0;
  `}
`

function ArticleHeader({ title, createdAt, createdAtDate, authorImage }) {
  return (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <Image fluid={authorImage.fluid}></Image>
        <AuthorName>Nuttavut Thongjor</AuthorName>
        <PublishedDate datetime={createdAtDate}>{createdAt}</PublishedDate>
      </Wrapper>
      <Break></Break>
    </>
  )
}

export default ArticleHeader
