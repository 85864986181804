import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacers.normal};
`

function SectionList({ children }) {
  return <Wrapper>{children}</Wrapper>
}

export default SectionList
